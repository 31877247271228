import fileDownload from "js-file-download";
import { writeDownloadFile } from "core/javascriptChannels";

export const handleDownload = async (content: string, filename: string): Promise<string> => {
  if (!!window.flutter_inappwebview) {
    const status = await writeDownloadFile(content, filename);
    return status;
  } else {
    fileDownload(content, filename);
    return "starting";
  }
};
