import Button from "@brighthr/component-button";
import { handleCopy } from "core/helpers/copy";
import { useTranslation } from "react-i18next";
import { Modal } from "shared/UI/Modal/Modal";
import { useGenerateRecoveryCodes } from "shared/core/hooks/useGenerateRecoveryCodes";
import { useNavigate } from "react-router-dom";
import { trackBtnClicks } from "core/monitoring/Events";
import { EventNames } from "core/monitoring/types/enums";
import { RecoveryCodesModalStatus } from "../../../../interfaces";
import { useEffect, useState } from "react";
import { useStatusModal } from "core/hooks/useStatusModal";
import { handleDownload } from "core/helpers/download";

interface RecoveryCodesModalProps {
  isVisible: boolean;
  onDismiss: () => void;
  status: RecoveryCodesModalStatus;
  code?: string;
}

export const RecoveryCodesModal = ({ isVisible, onDismiss, status, code }: RecoveryCodesModalProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: "accountSecurity.twoFactorAuth.modal.recoveryCodes" });
  const navigate = useNavigate();
  const [downloading, setDownloading] = useState(false);
  const { setBody, Modal: StatusModal } = useStatusModal();
  const { codes, loading, error } = useGenerateRecoveryCodes(isVisible, code);
  const allCodes = codes?.join("\n");

  const handleClose = () => {
    trackBtnClicks(EventNames.COMPLETE_RECOVERY_CODES_PROCESS);
    onDismiss();
    if (status === "new") {
      navigate("/account/account-security");
    }
  };

  const copyToClipboard = () => {
    handleCopy(allCodes);
    trackBtnClicks(EventNames.COPY_RECOVERY_CODES);
    setBody({
      data: t("copySuccess", { returnObjects: true }),
      status: "success",
    });
  };

  const handleFileDownload = async () => {
    setDownloading(true);
    trackBtnClicks(EventNames.DOWNLOAD_RECOVERY_CODES);

    const status = await handleDownload(allCodes, "Wisdom_Recovery_Codes.txt");
    const hasError = status === "error" || status === "cancelled";

    setBody({
      data: t(`download.${status}`, { returnObjects: true, defaultValue: "" }),
      status: hasError ? "error" : "success",
    });
    setDownloading(false);
  };

  useEffect(() => {
    if (!!error) {
      setBody({
        data: t("failure", { returnObjects: true, error: t(`errors.${error}`) }),
        status: "error",
        callback: onDismiss,
      });
    }
  }, [error, onDismiss, setBody, t]);

  if (!isVisible || loading) {
    return null;
  }

  return (
    <>
      {!error && (
        <Modal width="sm" close={() => {}}>
          <div data-testid="two-fa-recoverycode-modal" className="space-y-6 p-8">
            <div className="space-y-2">
              <h3 className="w-full p-0 text-center">{t(`${status}.title`)}</h3>
              <p className="w-full text-center">{t(`${status}.description`)}</p>
            </div>
            <div className="space-y-2">
              <p className="w-full text-center">{t(`title`)}</p>
              <div className="grid grid-cols-2 gap-2">
                {codes?.map((item: string) => (
                  <div className="bg-silver text-center p-2 rounded-sm">{item}</div>
                ))}
              </div>
            </div>
            <div className="space-y-2">
              <Button outlineButton color="primary" fullWidth text={t("cta.copy")} onClick={copyToClipboard} />
              <Button
                outlineButton
                fullWidth
                color="primary"
                disabled={downloading}
                text={t(`cta.${downloading ? "downloading" : "download"}`)}
                onClick={handleFileDownload}
              />
            </div>
            <Button fullWidth color="primary" text={t("cta.finish")} onClick={handleClose} />
          </div>
        </Modal>
      )}
      {StatusModal}
    </>
  );
};
